<template>
    <div>
        <Loader v-if="loading"></Loader>
        <div v-else>
            <CRow v-if="quest != null">
                <CCol lg="12">
                    <CCard>
                        <CCardHeader class="header">
                            <h3>{{ quest.title }}</h3>
                        </CCardHeader>
                        <CCardBody>
                            <div class="quest-content">
                                <span><strong>Título del cuestionario: </strong>{{ quest.title }}</span>
                                <span><strong>Descripción del cuestionario: </strong>{{ quest.description }}</span>
                                <span><strong>Enfermedad: </strong>{{ quest.disease.name ? quest.disease.name : '-' }}</span>
                                <span><strong>Perioricidad (días): </strong>{{ quest.periodicity }}</span>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol lg="12">
                    <CCard class="answers-and-questions">
                        <CCardHeader class="header">
                            <span>Preguntas y respuestas</span>
                        </CCardHeader>
                        <CCardBody>
                            <div v-if="quest.questions != ''">
                                <fieldset class="p-2 m-2" v-for="(item, index) in quest.questions" :key="index">
                                    <div class="question-container">
                                        <span><strong>{{ item.body }}</strong></span>
                                        <div v-if="item.answers.length !== 0" class="answer-container">
                                            <fieldset class="p-2 m-2" v-for="(answer, index) in item.answers" :key="index">
                                                <span v-if="answer.answer != ''">- {{ answer.answer }}</span>
                                                <span v-else class="answer-empty">- Respuesta en blanco</span>
                                            </fieldset>
                                        </div>
                                        <div v-else class="answer-empty">
                                            Esta pregunta no tiene respuestas.
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div v-else>
                                No hay preguntas ni respuestas para este cuestionario.
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol lg="12">
                    <CCard class="answers-and-questions">
                        <CCardHeader class="header">
                            <span>Categorías</span>
                        </CCardHeader>
                        <CCardBody>
                            <div v-if="quest.categories != ''">
                                <fieldset class="p-2 m-2" v-for="(category, index) in auxCategories" :key="index">
                                    <div class="question-container">
                                        <span><strong>{{ category.name }}</strong></span>
                                        <div class="categories-container">
                                            <fieldset class="p-2 m-2">
                                                <span><b>Valor mínimo:</b> {{ category.value_from }}</span>
                                                <span><b>Valor máximo:</b> {{ category.value_to }}</span>
                                            </fieldset>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div v-else>
                                No hay categorías para este cuestionario.
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
    </div>
</template>
  
<script>
import { getQuestDataById } from '@/services/quests';
import ComponentEVA from '@/components/ComponentEVA.vue';
import Loader from '@/components/Loader.vue';
export default {
    name: "showQuest",
    components: {ComponentEVA, Loader},
    data() {
        return {
            loading: true,
            quest: null,
            auxCategories: [],
        }
    },
    created() {
        this.receiveData();
    },
    methods: {
        receiveData() {
            this.getQuest();
        },
        /**
        * Función en la que se obtiene la información del cuestionario y que también
        * llama el metodo receiveData para cargarlos.
        * 
        * @returns {object[]} El cuestionario obtenido.
        */
        getQuest() {
            getQuestDataById(this.$route.params.id)
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
                .then((response) => {
                    this.quest = response;
                    if (response.disease !== null) {
                        this.quest.disease.name = response.disease.name;
                    } else {
                        this.quest.disease = '-';
                    }
                    this.auxCategories = this.quest.categories.sort((a, b) => a.id - b.id);
                    this.loading = false;
                });
        },
    }
};
</script>
<style scoped>
.answer-empty{
  color: #D22020;
  font-size: 14px;
  font-weight: 500;
}
.question-container, .categories-container {
    margin: 0px 10px;
}

.categories-container span{
    display: block;
}

.answer-container {
    margin: 8px 20px;
}

.question-container>span {
    font-size: 20px;
}

.answer-container>span {
    font-size: 16px;
}

.quest-content span {
    display: block;
    font-size: 16px;
    margin-bottom: 7px;
}

.image-response {
    font-size: 16px;
    width: 300px;
    display: table-cell;
    border: 1px solid;
    border-color: #d8dbe0;
    text-align: center;
    vertical-align: middle;
    height: 180px;
    border-radius: 2px;
}

.quest-description {
    font-size: 15px;
}

.hardware-icon {
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-left: 5px;
}

@media (max-width: 639px) {
    .card-header h2 {
        font-size: 20px;
    }

    .answers-and-questions .card-body {
        padding: 10px 0;
    }

    .question-container {
        margin: 0 !important;
    }

    .answer-container {
        margin: 8px 0 !important;
    }

    .question-container>span>strong {
        font-size: 18px;
    }

    .answer-container>span {
        padding: 10px 5px;
        font-size: 14px
    }

    .quest-content span {
        font-size: 14px;
    }
    .image-response{
        font-size: 14px;
        width: auto;
        padding: 0 5px;
    }
}
</style>
