<template>
    <div class="loader">
        <img class="pulse" src="@/../public/sas-logo.png" alt="Loader">
    </div>
</template>

<script>
export default {
  name: "Loader",
  data() {
    return {};
  }
};
</script>

<style scoped>
.loader{
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 100px;
    width: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
}
.pulse {
  -webkit-animation: pulse 1s infinite ease-in-out alternate;
  animation: pulse 1s infinite ease-in-out alternate;
  width: 300px;
}
@keyframes pulse {
  from { transform: scale(0.8); }
  to { transform: scale(1.2); }
}
@media (max-width: 639px) {
  .loader{
    width: 300px;
  }
  .pulse{
    width: 100%;
  }
}
</style>