import axios from "axios";
import { decodeBackData } from "./utils/response";

/**
 * Realiza una solicitud GET para obtener los datos de cuestionarios.
 * 
 * @param {string} url - La URL de la API para obtener los datos.
 * @returns {Promise<object>} - Devuelve una promesa que se resuelve con los datos de los cuestionarios.
 */
export async function get(url) {
    return await axios.get(url, { headers: { token: localStorage.token } })
    .then(result => { return decodeBackData(result.data) })
    .catch(error => { throw error });
}

/**
 * Realiza una solicitud POST para crear un nuevo cuestionario.
 * 
 * @param {string} url - La URL de la API para crear el cuestionario.
 * @param {object} data - Los datos del nuevo cuestionario.
 * @returns {Promise<object>} - Devuelve una promesa que se resuelve con la respuesta de la API.
 */
export async function post(url, data) {
    return await axios.post(url, data, { headers: { token: localStorage.token } })
    .then(result => { return result.data })
    .catch(error => { throw error });
}

/**
 * Realiza una solicitud PATCH para actualizar un cuestionario existente.
 * 
 * @param {string} url - La URL de la API para actualizar el cuestionario.
 * @param {object} data - Los datos actualizados del cuestionario.
 * @returns {Promise<object>} - Devuelve una promesa que se resuelve con la respuesta de la API.
 */
export async function patch(url, data) {
    return await axios.patch(url, data, { headers: { token: localStorage.token } })
    .then(result => { return result.data })
    .catch(error => { throw error });
}

/**
 * Realiza una solicitud PUT para reemplazar completamente un cuestionario existente.
 * 
 * @param {string} url - La URL de la API para reemplazar el cuestionario.
 * @param {object} data - Los nuevos datos del cuestionario.
 * @returns {Promise<object>} - Devuelve una promesa que se resuelve con la respuesta de la API.
 */
export async function put(url, data) {
    return await axios.put(url, data, { headers: { token: localStorage.token } })
    .then(result => { return result.data })
    .catch(error => { throw error });
}

/**
 * Realiza una solicitud DELETE para eliminar un cuestionario.
 * 
 * @param {string} url - La URL de la API para eliminar el cuestionario.
 * @returns {Promise<object>} - Devuelve una promesa que se resuelve con la respuesta de la API.
 */
export async function del(url) {
    return await axios.delete(url, { headers: { token: localStorage.token } })
    .then(result => { return result.data })
    .catch(error => { throw error });
}
