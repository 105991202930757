export function decodeBackData(data) {
    decodeDataObject(data);
    return data
}
/** 
 * Función para decodificar un objeto o array
 */
function decodeDataObject(obj) {
    if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
            decodeDataObject(obj[i]);
        }
    } else if (typeof obj === "object" && obj !== null) {
        for (let prop in obj) {
            if (typeof obj[prop] === "string") {
                obj[prop] = replaceUnicodeEscapes(obj[prop]);
            } else if (typeof obj[prop] === "object") {
                decodeDataObject(obj[prop]);
            }
        }
    }
}
/** 
 * Función para reemplazar caracteres unicode
 */
function replaceUnicodeEscapes(text) {
    return text.replace(/\\u([\dA-F]{4})/gi, function (match, p1) {
        return decodeURI(String.fromCharCode(parseInt(p1, 16)));
    });
}