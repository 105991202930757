<template>
    <div class="eva-scale">
        <div class="scale">
            <div v-for="point in points" :key="point.value" class="point"
                :class="{ selected: selectedEVA == point.value, evaOpacity: selectedEVA }">
                <span class="material-symbols-outlined set-form" :style="'background-color: ' + point.color">
                    {{point.icon}}
                </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        selectedEVA: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            points: [
                { value: 0, icon: "sentiment_very_satisfied", color: "#00FF00" },
                { value: 1, icon: "sentiment_satisfied", color: "#9BFF00" },
                { value: 2, icon: "sentiment_neutral", color: "#F2FF00" },
                { value: 3, icon: "sentiment_dissatisfied", color: "#FFCC00" },
                { value: 4, icon: "sentiment_very_dissatisfied", color: "#FF6A00" },
                { value: 5, icon: "sentiment_extremely_dissatisfied", color: "#FF0000" },
            ],
        };
    }
};
</script>
<style scoped>
.set-form {
    border-radius: 50px;
}
.eva-scale {
    text-align: center;
}

.scale {
    display: flex;
    gap: 30px;
    margin-top: 10px;
    padding: 10px 5px;
    border-radius: 2px;
}

.point {
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
}

.evaOpacity{
    opacity: .4;
}

.circle {
    width: 40px;
    height: 40px;
    background-color: #007bff;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-bottom: 5px;
}

.material-symbols-outlined{
    font-size: 50px;
    width: 50px;
}

.label {
    font-size: 14px;
}

.point.selected {
    opacity: 1;
}

h4 {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
  .scale{
    width: auto;
    justify-content: center;
    gap: 10px;
  }
  .material-symbols-outlined{
    font-size: 45px;
  }
}
@media (max-width: 450px) {
    .scale{
        gap: 5px;
    }
    .material-symbols-outlined{
        font-size: 30px;
    }
}

</style>
